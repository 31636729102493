<template>
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 2C10.8203 2 5 7.8203 5 15V21C5 28.1797 10.8203 34 18 34C25.1797 34 31 28.1797 31 21V15C31 7.8203 25.1797 2 18 2ZM7 15C7 8.92487 11.9249 4 18 4C24.0751 4 29 8.92487 29 15V21C29 27.0751 24.0751 32 18 32C11.9249 32 7 27.0751 7 21V15ZM19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12V15C17 15.5523 17.4477 16 18 16C18.5523 16 19 15.5523 19 15V12Z" />
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill: var(--color, --fallback-color);
        }
    }
</style>
