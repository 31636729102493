<!-- eslint-disable max-len -->
<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 17.1667C13.5103 17.1667 17.1667 13.5103 17.1667 9.00001C17.1667 4.48968 13.5103 0.83334 9 0.83334C4.48967 0.83334 0.833328 4.48968 0.833328 9.00001C0.833328 13.5103 4.48967 17.1667 9 17.1667ZM15.6667 9.00001C15.6667 12.6819 12.6819 15.6667 9 15.6667C5.3181 15.6667 2.33333 12.6819 2.33333 9.00001C2.33333 5.31811 5.3181 2.33334 9 2.33334C12.6819 2.33334 15.6667 5.31811 15.6667 9.00001ZM7 8.25C6.58578 8.25 6.25 8.58579 6.25 9C6.25 9.41421 6.58578 9.75 7 9.75H9.02127H11C11.4142 9.75 11.75 9.41421 11.75 9C11.75 8.58579 11.4142 8.25 11 8.25H9.02127H7Z"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill: var(--color, --fallback-color);
        }
    }
</style>
