<!-- eslint-disable max-len -->
<template>
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4451 1.70847C14.7948 1.53516 15.2054 1.53516 15.5552 1.70848C15.7112 1.78579 15.8817 1.94234 16.4698 2.5304C17.0578 3.11844 17.2143 3.289 17.2916 3.445C17.4649 3.79473 17.4649 4.20534 17.2916 4.55507C17.2143 4.71106 17.0578 4.88163 16.4697 5.46966L15.7028 6.23664C14.1451 6.0954 12.9047 4.85506 12.7634 3.29739L13.5305 2.53036C14.1185 1.94232 14.2891 1.78578 14.4451 1.70847ZM11.4771 2.46237C11.4721 2.46723 11.4672 2.47216 11.4623 2.47716L2.83991 11.0995L2.79168 11.1477C2.32831 11.611 2.02953 11.9097 1.78877 12.2549C1.57549 12.5607 1.39896 12.8905 1.26282 13.2376C1.10913 13.6294 1.02633 14.0437 0.897914 14.6862L0.884546 14.7531L0.264577 17.853C0.215398 18.0989 0.292365 18.3531 0.469682 18.5304C0.647 18.7077 0.901204 18.7847 1.1471 18.7355L4.24696 18.1155L4.31383 18.1021L4.31386 18.1021C4.95635 17.9737 5.37064 17.8909 5.76244 17.7372C6.10952 17.6011 6.43937 17.4246 6.74518 17.2113C7.09039 16.9705 7.38909 16.6718 7.85234 16.2084L7.90055 16.1602L16.5295 7.53119C16.5301 7.53065 16.5306 7.53011 16.5311 7.52957L17.5304 6.53032L17.6163 6.4445L17.6163 6.44448C18.0771 5.98443 18.4339 5.6283 18.6356 5.22112C19.0169 4.45172 19.0169 3.54838 18.6357 2.77897C18.4339 2.37178 18.0772 2.01564 17.6164 1.55557L17.5304 1.46975L17.4446 1.38383C16.9846 0.923006 16.6284 0.56627 16.2212 0.364476C15.4518 -0.0168277 14.5485 -0.0168375 13.7791 0.364449C13.3719 0.566235 13.0157 0.922963 12.5556 1.38378L12.4698 1.4697L11.4771 2.46237ZM14.4485 7.49089L6.83989 15.0995C6.31234 15.6271 6.11033 15.8253 5.88711 15.981C5.67788 16.1269 5.45219 16.2477 5.21471 16.3408C4.96136 16.4402 4.68437 16.4983 3.95279 16.6446L1.95608 17.044L2.35542 15.0473C2.50174 14.3157 2.55986 14.0387 2.65924 13.7854C2.75239 13.5479 2.87317 13.3222 3.0191 13.113C3.17478 12.8897 3.37301 12.6877 3.90057 12.1602L11.5092 4.55162C11.9843 5.92687 13.0732 7.01584 14.4485 7.49089Z" />
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill: var(--color, --fallback-color);
        }
    }
</style>
