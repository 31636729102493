<!-- eslint-disable max-len -->
<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
    </svg>
</template>

<style lang="scss" scoped>
    svg {
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path {
            stroke: var(--color, --fallback-color);
        }
    }
</style>
