<!-- eslint-disable max-len -->
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 22L15.5 21.5L17.0804 19.9196C19.8525 17.1475 21.2386 15.7614 21.7579 14.1631C22.2147 12.7572 22.2147 11.2428 21.7579 9.83688C21.2386 8.23858 19.8525 6.85252 17.0804 4.08041L16 3L15.5 2.5M11 10C11 11.1046 10.1046 12 9 12C7.89543 12 7 11.1046 7 10C7 8.89543 7.89543 8 9 8C10.1046 8 11 8.89543 11 10ZM16.4745 7.47452L13.8745 4.87452C13.1827 4.18271 12.8368 3.83681 12.4331 3.58944C12.0753 3.37012 11.6851 3.20851 11.2769 3.11052C10.8166 3 10.3274 3 9.34903 3H8.4C6.15979 3 5.03968 3 4.18404 3.43597C3.43139 3.81947 2.81947 4.43139 2.43597 5.18404C2 6.03968 2 7.15979 2 9.4V10.349C2 11.3274 2 11.8166 2.11052 12.2769C2.20851 12.6851 2.37012 13.0753 2.58944 13.4331C2.83681 13.8368 3.18271 14.1827 3.87451 14.8745L6.47452 17.4745C8.05858 19.0586 8.85062 19.8506 9.76393 20.1474C10.5673 20.4084 11.4327 20.4084 12.2361 20.1474C13.1494 19.8506 13.9414 19.0586 15.5255 17.4745L16.4745 16.5255C18.0586 14.9414 18.8506 14.1494 19.1474 13.2361C19.4084 12.4327 19.4084 11.5673 19.1474 10.7639C18.8506 9.85062 18.0586 9.05859 16.4745 7.47452Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            stroke: var(--color, --fallback-color);
        }
    }
</style>
