<!-- eslint-disable max-len -->
<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99999 17.1667C13.5103 17.1667 17.1667 13.5103 17.1667 9.00001C17.1667 4.48968 13.5103 0.83334 8.99999 0.83334C4.48967 0.83334 0.833328 4.48968 0.833328 9.00001C0.833328 13.5103 4.48967 17.1667 8.99999 17.1667ZM15.6667 9.00001C15.6667 12.6819 12.6819 15.6667 8.99999 15.6667C5.3181 15.6667 2.33333 12.6819 2.33333 9.00001C2.33333 5.31811 5.3181 2.33334 8.99999 2.33334C12.6819 2.33334 15.6667 5.31811 15.6667 9.00001ZM7.53033 7.85288C7.23744 7.55999 6.76256 7.55999 6.46967 7.85288C6.17678 8.14577 6.17678 8.62065 6.46967 8.91354L8.46967 10.9135C8.61032 11.0542 8.80109 11.1332 9 11.1332C9.19891 11.1332 9.38968 11.0542 9.53033 10.9135L11.5303 8.91354C11.8232 8.62065 11.8232 8.14577 11.5303 7.85288C11.2374 7.55999 10.7626 7.55999 10.4697 7.85288L9 9.32255L7.53033 7.85288Z"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill: var(--color, --fallback-color);
        }
    }
</style>
