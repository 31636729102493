<!-- eslint-disable max-len -->
<template>
    <!-- boilerplate setup for inline SVGS -->
    <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.5 8.5V1.5M4.5 1.5L1 5M4.5 1.5L8 5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        width: var(--size, auto);
        height: var(--size, auto);

        path{
            stroke: var(--color, --fallback-color);
        }
    }
</style>
