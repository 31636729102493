<!-- eslint-disable max-len -->
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 12C3.75 7.44365 7.44365 3.75 12 3.75C16.5563 3.75 20.25 7.44365 20.25 12C20.25 16.5564 16.5563 20.25 12 20.25C7.44365 20.25 3.75 16.5564 3.75 12ZM12 2.25C6.61522 2.25 2.25 6.61523 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61523 17.3848 2.25 12 2.25ZM16.0303 10.0303C16.3232 9.73744 16.3232 9.26257 16.0303 8.96967C15.7374 8.67678 15.2626 8.67678 14.9697 8.96967L10.5 13.4393L9.03033 11.9697C8.73744 11.6768 8.26256 11.6768 7.96967 11.9697C7.67678 12.2626 7.67678 12.7374 7.96967 13.0303L9.96967 15.0303C10.1103 15.171 10.3011 15.25 10.5 15.25C10.6989 15.25 10.8897 15.171 11.0303 15.0303L16.0303 10.0303Z"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill: var(--color, --fallback-color);
        }
    }
</style>
