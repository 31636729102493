<!-- eslint-disable max-len -->
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.2827 3.4533C11.5131 2.98637 11.6284 2.7529 11.7848 2.6783C11.9209 2.6134 12.0791 2.6134 12.2152 2.6783C12.3717 2.7529 12.4869 2.98637 12.7174 3.4533L14.9041 7.88327C14.9721 8.02112 15.0061 8.09005 15.0558 8.14356C15.0999 8.19095 15.1527 8.22934 15.2113 8.25661C15.2776 8.28741 15.3536 8.29853 15.5057 8.32076L20.397 9.03569C20.9121 9.11098 21.1696 9.14862 21.2888 9.27443C21.3925 9.38388 21.4412 9.53428 21.4215 9.68376C21.3988 9.85557 21.2124 10.0372 20.8395 10.4004L17.3014 13.8464C17.1912 13.9538 17.136 14.0075 17.1004 14.0715C17.0689 14.128 17.0487 14.1902 17.0409 14.2545C17.0321 14.3271 17.0451 14.403 17.0711 14.5547L17.906 19.4221C17.994 19.9355 18.038 20.1922 17.9553 20.3445C17.8833 20.477 17.7554 20.57 17.6071 20.5975C17.4366 20.6291 17.2061 20.5078 16.7451 20.2654L12.3724 17.9658C12.2361 17.8942 12.168 17.8583 12.0962 17.8443C12.0327 17.8318 11.9673 17.8318 11.9038 17.8443C11.832 17.8583 11.7639 17.8942 11.6277 17.9658L7.25492 20.2654C6.79392 20.5078 6.56341 20.6291 6.39297 20.5975C6.24468 20.57 6.11672 20.477 6.04474 20.3445C5.962 20.1922 6.00603 19.9355 6.09407 19.4221L6.92889 14.5547C6.95491 14.403 6.96793 14.3271 6.95912 14.2545C6.95132 14.1902 6.93111 14.128 6.89961 14.0715C6.86402 14.0075 6.80888 13.9538 6.69859 13.8464L3.16056 10.4004C2.78766 10.0372 2.60121 9.85557 2.57853 9.68376C2.55879 9.53428 2.60755 9.38388 2.71125 9.27443C2.83044 9.14862 3.08797 9.11098 3.60304 9.03569L8.49431 8.32076C8.64642 8.29853 8.72248 8.28741 8.78872 8.25661C8.84736 8.22934 8.90016 8.19095 8.94419 8.14356C8.99391 8.09005 9.02793 8.02112 9.09597 7.88327L11.2827 3.4533Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            stroke: var(--color, --fallback-color);
        }
    }
</style>
