<!-- eslint-disable max-len -->
<template>
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 2C9.11225 2 2 9.16558 2 18C2 18.5523 2.44772 19 3 19C3.55228 19 4 18.5523 4 18C4 10.2659 10.2211 4 18 4C22.7064 4 26.2389 5.93721 28.6136 7.89637C29.6265 8.73198 30.4235 9.56779 31.0033 10.25H27.75C27.1977 10.25 26.75 10.6977 26.75 11.25C26.75 11.8023 27.1977 12.25 27.75 12.25H33C33.5523 12.25 34 11.8023 34 11.25V6C34 5.44772 33.5523 5 33 5C32.4477 5 32 5.44772 32 6V8.35826C31.429 7.73666 30.7247 7.0452 29.8864 6.35363C27.2611 4.18779 23.2936 2 18 2ZM34 18C34 17.4477 33.5523 17 33 17C32.4477 17 32 17.4477 32 18C32 25.732 25.732 32 18 32C14.1989 32 11.051 30.1053 8.80471 28.1249C7.8496 27.2829 7.07385 26.4393 6.49931 25.75H9.75C10.3023 25.75 10.75 25.3023 10.75 24.75C10.75 24.1977 10.3023 23.75 9.75 23.75H4.5C3.94772 23.75 3.5 24.1977 3.5 24.75V30C3.5 30.5523 3.94772 31 4.5 31C5.05228 31 5.5 30.5523 5.5 30V27.6527C6.04662 28.2646 6.71002 28.9444 7.48209 29.6251C9.91469 31.7697 13.5169 34 18 34C26.8366 34 34 26.8366 34 18ZM22.9571 15.7071C23.3476 15.3166 23.3476 14.6834 22.9571 14.2929C22.5666 13.9024 21.9334 13.9024 21.5429 14.2929L16.25 19.5858L14.7071 18.0429C14.3166 17.6524 13.6834 17.6524 13.2929 18.0429C12.9024 18.4334 12.9024 19.0666 13.2929 19.4571L15.5429 21.7071C15.7304 21.8946 15.9848 22 16.25 22C16.5152 22 16.7696 21.8946 16.9571 21.7071L22.9571 15.7071Z" />
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill: var(--color, --fallback-color);
        }
    }
</style>
