<!-- eslint-disable max-len -->
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 4.75C8.58579 4.75 8.25 4.41421 8.25 4C8.25 3.58579 8.58579 3.25 9 3.25H20C20.4142 3.25 20.75 3.58579 20.75 4V15C20.75 15.4142 20.4142 15.75 20 15.75C19.5858 15.75 19.25 15.4142 19.25 15V5.81066L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L18.1893 4.75H9Z"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill: var(--color, --fallback-color);
        }
    }
</style>
