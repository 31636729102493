<!-- eslint-disable max-len -->
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="ring" fill-rule="evenodd" clip-rule="evenodd" d="M4.76986 4.92384C6.36338 3.59591 8.77743 2.25 12 2.25C17.4158 2.25 21.75 6.61683 21.75 12C21.75 12.4142 21.4142 12.75 21 12.75C20.5858 12.75 20.25 12.4142 20.25 12C20.25 7.44205 16.5842 3.75 12 3.75C9.22257 3.75 7.13662 4.9041 5.73014 6.07617C5.24073 6.48401 4.83635 6.892 4.51757 7.25H6C6.41421 7.25 6.75 7.58579 6.75 8C6.75 8.41422 6.41421 8.75 6 8.75H3C2.58579 8.75 2.25 8.41422 2.25 8V3.5C2.25 3.08579 2.58579 2.75 3 2.75C3.41421 2.75 3.75 3.08579 3.75 3.5V5.87217C4.04305 5.56864 4.38294 5.24627 4.76986 4.92384ZM3 11.25C3.41421 11.25 3.75 11.5858 3.75 12C3.75 16.5564 7.44365 20.25 12 20.25C14.2251 20.25 16.0526 19.13 17.3602 17.9444C17.8176 17.5297 18.2031 17.1142 18.5105 16.75H17.9355C17.5213 16.75 17.1855 16.4142 17.1855 16C17.1855 15.5858 17.5213 15.25 17.9355 15.25H20C20.4142 15.25 20.75 15.5858 20.75 16V20.5C20.75 20.9142 20.4142 21.25 20 21.25C19.5858 21.25 19.25 20.9142 19.25 20.5V18.1789C18.9904 18.4609 18.6959 18.7581 18.3677 19.0556C16.918 20.37 14.7455 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12C2.25 11.5858 2.58579 11.25 3 11.25Z"/>
        <path id="euro" d="M15 9.35418C14.2671 8.52375 13.1947 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C13.1947 16 14.2671 15.4762 15 14.6458M7 11H12M7 13H11" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{

            &#ring{
                fill: var(--color, --fallback-color);
            }
            &#euro{
                stroke: var(--color, --fallback-color);
            }
        }
    }
</style>
