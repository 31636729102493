<!-- eslint-disable max-len -->
<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 4.2C2.5 3.0799 2.5 2.51984 2.71799 2.09202C2.90973 1.71569 3.21569 1.40973 3.59202 1.21799C4.01984 1 4.5799 1 5.7 1H8.95229C9.44147 1 9.68607 1 9.91624 1.05526C10.1203 1.10425 10.3154 1.18506 10.4943 1.29472C10.6962 1.4184 10.8691 1.59136 11.215 1.93726L12.4516 3.17385C12.7975 3.51976 12.9705 3.69271 13.0942 3.89454C13.2038 4.07349 13.2846 4.26858 13.3336 4.47265C13.3889 4.70282 13.3889 4.94741 13.3889 5.43659V11.8C13.3889 12.9201 13.3889 13.4802 13.1709 13.908C12.9792 14.2843 12.6732 14.5903 12.2969 14.782C11.869 15 11.309 15 10.1889 15H5.7C4.5799 15 4.01984 15 3.59202 14.782C3.21569 14.5903 2.90973 14.2843 2.71799 13.908C2.5 13.4802 2.5 12.9201 2.5 11.8V4.2Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.94444 7.22229V11.889M7.94444 11.889L9.88889 9.94451M7.94444 11.889L6 9.94451" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.5 1.38893V2.75562C9.5 3.50236 9.5 3.87573 9.64532 4.16094C9.77316 4.41182 9.97713 4.6158 10.228 4.74363C10.5132 4.88895 10.8866 4.88895 11.6333 4.88895H13" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            stroke: var(--color, --fallback-color);
        }
    }
</style>
