<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5068 5.4471C20.8121 5.72699 20.8328 6.20142 20.5529 6.50676L9.55287 18.5068C9.41464 18.6576 9.22081 18.7453 9.01629 18.7498C8.81178 18.7542 8.61432 18.6749 8.46967 18.5303L3.46967 13.5303C3.17678 13.2374 3.17678 12.7625 3.46967 12.4696C3.76256 12.1767 4.23744 12.1767 4.53033 12.4696L8.97644 16.9157L19.4471 5.49317C19.727 5.18783 20.2015 5.16721 20.5068 5.4471Z"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill: var(--color, --fallback-color);
        }
    }
</style>
