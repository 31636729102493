<!-- eslint-disable max-len -->
<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.95034 5.011C2.72972 6.44638 1.9397 8.25928 1.77997 10.25H3C3.41421 10.25 3.75 10.5858 3.75 11C3.75 11.4142 3.41421 11.75 3 11.75H1.77997C1.89927 13.2369 2.37019 14.6246 3.10952 15.8298L3.7019 15.2374C4.93976 13.9996 6.60665 13.2894 8.35307 13.2516C8.61172 12.335 9.33363 11.6127 10.25 11.3535V8C10.25 7.58579 10.5858 7.25 11 7.25C11.4142 7.25 11.75 7.58579 11.75 8V11.3535C12.6664 11.6127 13.3883 12.335 13.6469 13.2516C15.3934 13.2894 17.0602 13.9996 18.2981 15.2374L18.8905 15.8298C19.6298 14.6246 20.1007 13.2369 20.22 11.75H19C18.5858 11.75 18.25 11.4142 18.25 11C18.25 10.5858 18.5858 10.25 19 10.25H20.22C20.0603 8.25932 19.2703 6.44646 18.0498 5.0111L17.5302 5.53043C17.2373 5.82327 16.7624 5.82318 16.4696 5.53023C16.1767 5.23729 16.1768 4.76241 16.4698 4.46957L16.9891 3.95042C15.5537 2.72976 13.7408 1.9397 11.75 1.77997V3C11.75 3.41421 11.4142 3.75 11 3.75C10.5858 3.75 10.25 3.41421 10.25 3V1.77997C8.25928 1.9397 6.44638 2.72972 5.011 3.95034L5.53033 4.46967C5.82322 4.76256 5.82322 5.23744 5.53033 5.53033C5.23744 5.82322 4.76256 5.82322 4.46967 5.53033L3.95034 5.011ZM11 12.75C10.3096 12.75 9.75 13.3096 9.75 14C9.75 14.6904 10.3096 15.25 11 15.25C11.6904 15.25 12.25 14.6904 12.25 14C12.25 13.3096 11.6904 12.75 11 12.75ZM4.76256 16.2981C5.71935 15.3413 7.00518 14.7892 8.35409 14.752C8.68122 15.9052 9.74195 16.75 11 16.75C12.258 16.75 13.3188 15.9052 13.6459 14.752C14.9948 14.7892 16.2807 15.3413 17.2374 16.2981L17.9936 17.0543C16.2976 19.0118 13.7934 20.25 11 20.25C8.20662 20.25 5.70244 19.0118 4.00637 17.0543L4.76256 16.2981ZM21.75 11C21.75 5.06294 16.9371 0.25 11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11Z"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill: var(--color, --fallback-color);
        }
    }
</style>
