<!-- eslint-disable max-len -->
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3.25C5.16751 3.25 1.25 7.16751 1.25 12C1.25 16.8325 5.16751 20.75 10 20.75H14C18.8325 20.75 22.75 16.8325 22.75 12C22.75 7.16751 18.8325 3.25 14 3.25H10ZM2.75 12C2.75 7.99594 5.99594 4.75 10 4.75H14C18.0041 4.75 21.25 7.99594 21.25 12C21.25 16.0041 18.0041 19.25 14 19.25H10C5.99594 19.25 2.75 16.0041 2.75 12ZM6.75 12C6.75 10.2051 8.20507 8.75 10 8.75C11.7949 8.75 13.25 10.2051 13.25 12C13.25 13.7949 11.7949 15.25 10 15.25C8.20507 15.25 6.75 13.7949 6.75 12ZM10 7.25C7.37665 7.25 5.25 9.37665 5.25 12C5.25 14.6234 7.37665 16.75 10 16.75C12.6234 16.75 14.75 14.6234 14.75 12C14.75 9.37665 12.6234 7.25 10 7.25Z"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill: var(--color, --fallback-color);
        }
    }
</style>
