<!-- eslint-disable max-len -->
<template>
    <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.395 3.13878C15.222 2.55988 15.8 2.15621 16.2552 1.89586C16.7184 1.63098 16.9362 1.58712 17.0546 1.5923C17.4238 1.60845 17.7668 1.78707 17.9917 2.08019C18.0639 2.17427 18.1529 2.37784 18.2015 2.90916C18.2492 3.43143 18.25 4.13642 18.25 5.14589V6.99991L18.25 12.9999L18.25 14.8537C18.25 15.8632 18.2492 16.5682 18.2015 17.0904C18.1529 17.6217 18.0639 17.8253 17.9917 17.9194C17.7668 18.2125 17.4238 18.3911 17.0546 18.4073C16.9362 18.4125 16.7184 18.3686 16.2553 18.1037C15.8 17.8434 15.222 17.4397 14.395 16.8608L9.75 13.6093L9.75 6.39029L14.395 3.13878ZM19.75 13.6749V14.8537V14.8925C19.75 15.8541 19.75 16.6282 19.6952 17.2271C19.6405 17.825 19.5229 18.388 19.1818 18.8325C18.687 19.4774 17.9323 19.8703 17.1202 19.9059C16.5605 19.9303 16.0318 19.7039 15.5106 19.4058C14.9886 19.1073 14.3545 18.6635 13.5669 18.1121L13.5668 18.112L13.5667 18.1119L13.5666 18.1119L13.5348 18.0897L8.76393 14.75H8.75L8.75 18.5002C8.75 19.7428 7.74264 20.7502 6.5 20.7502C5.25736 20.7502 4.25 19.7428 4.25 18.5002L4.25 14.6912C1.98301 14.3316 0.25 12.3682 0.25 10C0.25 7.37669 2.37665 5.25004 5 5.25004L8.76323 5.25004L13.5348 1.90993L13.5666 1.88766C14.3544 1.33621 14.9885 0.8923 15.5106 0.593747C16.0318 0.295702 16.5605 0.0692396 17.1202 0.0937333C17.9323 0.129267 18.687 0.522222 19.1818 1.1671C19.5229 1.61162 19.6405 2.17463 19.6952 2.7725C19.75 3.37135 19.75 4.14536 19.75 5.10689V5.10707V5.14589V6.32493C21.4617 6.67238 22.75 8.1857 22.75 9.99991C22.75 11.8141 21.4617 13.3274 19.75 13.6749ZM19.75 12.1219C20.6239 11.813 21.25 10.9796 21.25 9.99991C21.25 9.02025 20.6239 8.18682 19.75 7.87794V12.1219ZM8.25 6.75004L5 6.75004C3.20507 6.75004 1.75 8.20511 1.75 10C1.75 11.795 3.20508 13.25 5 13.25L8.25 13.25L8.25 6.75004ZM6.5 19.2502C6.91422 19.2502 7.25 18.9144 7.25 18.5002L7.25 14.7502H5.75L5.75 18.5002C5.75 18.9144 6.08579 19.2502 6.5 19.2502Z"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill: var(--color, --fallback-color);
        }
    }
</style>
