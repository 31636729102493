<!-- eslint-disable max-len -->
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.75 2C12.75 1.58579 12.4142 1.25 12 1.25C11.5858 1.25 11.25 1.58579 11.25 2V3.25H9.48683C7.14689 3.25 5.25 5.1469 5.25 7.48684C5.25 9.3105 6.41695 10.9296 8.14703 11.5063L11.25 12.5406L11.25 19.25H10C8.20507 19.25 6.75 17.7949 6.75 16C6.75 15.5858 6.41421 15.25 6 15.25C5.58579 15.25 5.25 15.5858 5.25 16C5.25 18.6234 7.37665 20.75 10 20.75H11.25V22C11.25 22.4142 11.5858 22.75 12 22.75C12.4142 22.75 12.75 22.4142 12.75 22V20.75H14.5132C16.8531 20.75 18.75 18.8531 18.75 16.5132C18.75 14.6895 17.583 13.0704 15.853 12.4938L12.75 11.4594L12.75 4.75H14C15.7949 4.75 17.25 6.20508 17.25 8C17.25 8.41422 17.5858 8.75 18 8.75C18.4142 8.75 18.75 8.41422 18.75 8C18.75 5.37665 16.6234 3.25 14 3.25H12.75V2ZM11.25 4.75H9.48683C7.97532 4.75 6.75 5.97533 6.75 7.48684C6.75 8.66485 7.50381 9.7107 8.62137 10.0832L11.25 10.9594L11.25 4.75ZM12.75 13.0406L12.75 19.25H14.5132C16.0247 19.25 17.25 18.0247 17.25 16.5132C17.25 15.3352 16.4962 14.2893 15.3786 13.9168L12.75 13.0406Z"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill: var(--color, --fallback-color);
        }
    }
</style>
