<!-- eslint-disable max-len -->
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3.25C5.16751 3.25 1.25 7.16751 1.25 12C1.25 16.8325 5.16751 20.75 10 20.75H14C18.8325 20.75 22.75 16.8325 22.75 12C22.75 7.16751 18.8325 3.25 14 3.25H10ZM2.75 12C2.75 7.99594 5.99594 4.75 10 4.75H14C18.0041 4.75 21.25 7.99594 21.25 12C21.25 16.0041 18.0041 19.25 14 19.25H10C5.99594 19.25 2.75 16.0041 2.75 12ZM10.75 12C10.75 10.2051 12.2051 8.75 14 8.75C15.7949 8.75 17.25 10.2051 17.25 12C17.25 13.7949 15.7949 15.25 14 15.25C12.2051 15.25 10.75 13.7949 10.75 12ZM14 7.25C11.3766 7.25 9.25 9.37665 9.25 12C9.25 14.6234 11.3766 16.75 14 16.75C16.6234 16.75 18.75 14.6234 18.75 12C18.75 9.37665 16.6234 7.25 14 7.25Z"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill: var(--color, --fallback-color);
        }
    }
</style>
