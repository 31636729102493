<!-- eslint-disable max-len -->
<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99999 0.833326C13.5103 0.833326 17.1667 4.48967 17.1667 8.99999C17.1667 13.5103 13.5103 17.1667 8.99999 17.1667C4.48967 17.1667 0.833328 13.5103 0.833328 8.99999C0.833328 4.48967 4.48967 0.833326 8.99999 0.833326ZM15.6667 8.99999C15.6667 5.31809 12.6819 2.33333 8.99999 2.33333C5.3181 2.33333 2.33333 5.31809 2.33333 8.99999C2.33333 12.6819 5.3181 15.6667 8.99999 15.6667C12.6819 15.6667 15.6667 12.6819 15.6667 8.99999ZM7.53033 10.1471C7.23744 10.44 6.76256 10.44 6.46967 10.1471C6.17678 9.85422 6.17678 9.37935 6.46967 9.08646L8.46967 7.08646C8.61032 6.9458 8.80109 6.86679 9 6.86679C9.19891 6.86679 9.38968 6.9458 9.53033 7.08646L11.5303 9.08646C11.8232 9.37935 11.8232 9.85422 11.5303 10.1471C11.2374 10.44 10.7626 10.44 10.4697 10.1471L9 8.67745L7.53033 10.1471Z"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill: var(--color, --fallback-color);
        }
    }
</style>
