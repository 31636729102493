<!-- eslint-disable max-len -->
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 9V14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.336 21.9309 17.1501 21.9912 15 21.9989M2 9V14C2 16.8003 2 18.2004 2.54497 19.27C3.02433 20.2108 3.78924 20.9757 4.73005 21.455C5.66397 21.9309 6.84993 21.9912 9 21.9989M15 21.9989V17C15 16.0681 15 15.6022 14.8478 15.2346C14.6448 14.7446 14.2554 14.3552 13.7654 14.1522C13.3978 14 12.9319 14 12 14C11.0681 14 10.6022 14 10.2346 14.1522C9.74458 14.3552 9.35523 14.7446 9.15224 15.2346C9 15.6022 9 16.0681 9 17V21.9989M15 21.9989V22M15 21.9989C14.6877 22 14.3551 22 14 22H10C9.64488 22 9.31227 22 9 21.9989M9 22V21.9989M3.45455 1C2.09894 1 1 2.09894 1 3.45455V6.5C1 8.433 2.567 10 4.5 10C6.433 10 8 8.433 8 6.5V5.5V6C8 8.20914 9.79086 10 12 10C14.2091 10 16 8.20914 16 6V5.5V6.5C16 8.433 17.567 10 19.5 10C21.433 10 23 8.433 23 6.5V3.45455C23 2.09894 21.9011 1 20.5455 1H3.45455Z"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill:none;
            stroke: var(--color, --fallback-color);
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
        }
    }
</style>
