<!-- eslint-disable max-len -->
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8931 3.75C10.2317 3.75 7.12907 6.05052 5.9782 9.25H12.75C13.1642 9.25 13.5 9.58579 13.5 10C13.5 10.4142 13.1642 10.75 12.75 10.75H5.59609C5.53297 11.1578 5.50024 11.5752 5.50024 12C5.50024 12.4248 5.53297 12.8422 5.59609 13.25H10.75C11.1642 13.25 11.5 13.5858 11.5 14C11.5 14.4142 11.1642 14.75 10.75 14.75H5.9782C7.12907 17.9495 10.2317 20.25 13.8931 20.25C16.404 20.25 18.6542 19.1673 20.1918 17.4524C20.4684 17.144 20.9425 17.1182 21.2509 17.3947C21.5593 17.6712 21.5852 18.1454 21.3087 18.4538C19.4956 20.4758 16.8437 21.75 13.8931 21.75C9.40609 21.75 5.60451 18.8013 4.39889 14.75H2.75C2.33579 14.75 2 14.4142 2 14C2 13.5858 2.33579 13.25 2.75 13.25H4.08069C4.02761 12.8408 4.00024 12.4236 4.00024 12C4.00024 11.5764 4.02761 11.1592 4.08069 10.75H2.75C2.33579 10.75 2 10.4142 2 10C2 9.58579 2.33579 9.25 2.75 9.25H4.39889C5.60451 5.19866 9.40609 2.25 13.8931 2.25C16.8437 2.25 19.4956 3.52419 21.3087 5.54621C21.5852 5.85461 21.5593 6.32878 21.2509 6.6053C20.9425 6.88182 20.4684 6.85598 20.1918 6.54758C18.6542 4.83271 16.404 3.75 13.8931 3.75Z"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill: var(--color, --fallback-color);
        }
    }
</style>
