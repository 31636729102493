<!-- eslint-disable max-len -->
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 22V15C15 14.0681 15 13.6022 14.8478 13.2346C14.6448 12.7446 14.2554 12.3552 13.7654 12.1522C13.3978 12 12.9319 12 12 12V12C11.0681 12 10.6022 12 10.2346 12.1522C9.74458 12.3552 9.35523 12.7446 9.15224 13.2346C9 13.6022 9 14.0681 9 15V22M7.00244 3.99805L5.00244 5.59805C3.89986 6.48011 3.34857 6.92115 2.95174 7.46857C2.6002 7.95353 2.33865 8.49771 2.17957 9.07517C2 9.72701 2 10.433 2 11.845V14C2 16.8003 2 18.2004 2.54497 19.27C3.02433 20.2108 3.78924 20.9757 4.73005 21.455C5.79961 22 7.19974 22 10 22H14C16.8003 22 18.2004 22 19.27 21.455C20.2108 20.9757 20.9757 20.2108 21.455 19.27C22 18.2004 22 16.8003 22 14V11.845C22 10.433 22 9.72701 21.8204 9.07517C21.6613 8.49771 21.3998 7.95353 21.0483 7.46857C20.6514 6.92115 20.1001 6.48011 18.9976 5.59805L16.9976 3.99805C15.214 2.57118 14.3222 1.85774 13.3332 1.58413C12.4608 1.34279 11.5392 1.34279 10.6668 1.58413C9.67783 1.85774 8.78603 2.57118 7.00244 3.99805Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            stroke: var(--color, --fallback-color);
        }
    }
</style>
