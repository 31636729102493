<!-- eslint-disable max-len -->
<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.41665 2C5.41665 1.58579 5.08087 1.25 4.66665 1.25C4.25244 1.25 3.91665 1.58579 3.91665 2L3.91665 4.02018C2.7624 4.34665 1.91665 5.40789 1.91665 6.66667C1.91665 8.18545 3.14787 9.41667 4.66665 9.41667C6.18543 9.41667 7.41665 8.18545 7.41665 6.66667C7.41665 5.40789 6.5709 4.34665 5.41665 4.02018V2ZM3.41665 6.66667C3.41665 5.97631 3.9763 5.41667 4.66665 5.41667C5.35701 5.41667 5.91665 5.97631 5.91665 6.66667C5.91665 7.35702 5.35701 7.91667 4.66665 7.91667C3.9763 7.91667 3.41665 7.35702 3.41665 6.66667ZM5.41665 10.6667C5.41665 10.2525 5.08087 9.91667 4.66665 9.91667C4.25244 9.91667 3.91665 10.2525 3.91665 10.6667V14C3.91665 14.4142 4.25244 14.75 4.66665 14.75C5.08086 14.75 5.41665 14.4142 5.41665 14V10.6667ZM12.0833 2C12.0833 1.58579 11.7475 1.25 11.3333 1.25C10.9191 1.25 10.5833 1.58579 10.5833 2V5.33333C10.5833 5.74755 10.9191 6.08333 11.3333 6.08333C11.7475 6.08333 12.0833 5.74755 12.0833 5.33333V2ZM10.5833 11.9798C9.42907 11.6534 8.58332 10.5921 8.58332 9.33333C8.58332 7.81455 9.81454 6.58333 11.3333 6.58333C12.8521 6.58333 14.0833 7.81455 14.0833 9.33333C14.0833 10.5921 13.2376 11.6534 12.0833 11.9798L12.0833 14C12.0833 14.4142 11.7475 14.75 11.3333 14.75C10.9191 14.75 10.5833 14.4142 10.5833 14L10.5833 11.9798ZM12.5833 9.33333C12.5833 10.0237 12.0237 10.5833 11.3333 10.5833C10.643 10.5833 10.0833 10.0237 10.0833 9.33333C10.0833 8.64298 10.643 8.08333 11.3333 8.08333C12.0237 8.08333 12.5833 8.64298 12.5833 9.33333Z"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill: var(--color, --fallback-color);
        }
    }
</style>
