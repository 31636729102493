<template>
    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle id="outer" opacity="0.8" cx="5.5" cy="6" r="5" stroke="#FF6B00" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="3 3"/>
        <circle id="inner" opacity="0.8" cx="5.5" cy="6" r="2" fill="#FF6B00"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;
    }
</style>
