<!-- eslint-disable max-len -->
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.75 6C4.75 4.20508 6.20507 2.75 8 2.75C9.79493 2.75 11.25 4.20508 11.25 6C11.25 7.79493 9.79493 9.25 8 9.25C6.20507 9.25 4.75 7.79493 4.75 6ZM8 1.25C5.37665 1.25 3.25 3.37665 3.25 6C3.25 8.62336 5.37665 10.75 8 10.75C10.6234 10.75 12.75 8.62336 12.75 6C12.75 3.37665 10.6234 1.25 8 1.25ZM15 1.25C14.5858 1.25 14.25 1.58579 14.25 2C14.25 2.41422 14.5858 2.75 15 2.75C16.7949 2.75 18.25 4.20508 18.25 6C18.25 7.79493 16.7949 9.25 15 9.25C14.5858 9.25 14.25 9.58579 14.25 10C14.25 10.4142 14.5858 10.75 15 10.75C17.6234 10.75 19.75 8.62336 19.75 6C19.75 3.37665 17.6234 1.25 15 1.25ZM0.25 18.8C0.25 15.7348 2.73482 13.25 5.8 13.25H10.2C13.2652 13.25 15.75 15.7348 15.75 18.8C15.75 20.9815 13.9815 22.75 11.8 22.75H4.2C2.01848 22.75 0.25 20.9815 0.25 18.8ZM14.25 18.8C14.25 16.5633 12.4368 14.75 10.2 14.75H5.8C3.56325 14.75 1.75 16.5633 1.75 18.8C1.75 20.1531 2.8469 21.25 4.2 21.25H11.8C13.1531 21.25 14.25 20.1531 14.25 18.8ZM17 13.25C16.5858 13.25 16.25 13.5858 16.25 14C16.25 14.4142 16.5858 14.75 17 14.75H18.2C20.4368 14.75 22.25 16.5633 22.25 18.8C22.25 20.1531 21.1531 21.25 19.8 21.25H17C16.5858 21.25 16.25 21.5858 16.25 22C16.25 22.4142 16.5858 22.75 17 22.75H19.8C21.9815 22.75 23.75 20.9815 23.75 18.8C23.75 15.7348 21.2652 13.25 18.2 13.25H17Z"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill: var(--color, --fallback-color);
        }
    }
</style>
