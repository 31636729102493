<!-- eslint-disable max-len -->
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1.25C8.15374 1.25 4.84218 3.96483 4.08786 7.7364L2.83407 14.0054C2.43135 16.019 3.50025 18.0431 5.36757 18.873C6.04939 19.176 6.74921 19.4298 7.46118 19.6343L7.56494 19.8678C8.34391 21.6205 10.082 22.75 12 22.75C13.918 22.75 15.6561 21.6205 16.4351 19.8678L16.5388 19.6343C17.2508 19.4298 17.9506 19.176 18.6324 18.873C20.4997 18.0431 21.5686 16.019 21.1659 14.0054L19.9121 7.7364C19.1578 3.96483 15.8463 1.25 12 1.25ZM15.8893 18.2581C15.8353 18.2662 15.7824 18.2802 15.7316 18.2997C13.2911 18.9283 10.7089 18.9283 8.26842 18.2997C8.21763 18.2802 8.16473 18.2662 8.11069 18.2581C7.38367 18.0623 6.66984 17.8103 5.97678 17.5023C4.74159 16.9533 4.04183 15.6151 4.30494 14.2995L5.55874 8.03057C6.17282 4.96014 8.86876 2.75 12 2.75C15.1312 2.75 17.8272 4.96014 18.4413 8.03057L19.6951 14.2995C19.9582 15.6151 19.2584 16.9533 18.0232 17.5023C17.3302 17.8103 16.6163 18.0623 15.8893 18.2581ZM9.44875 20.0731C11.1375 20.3372 12.8625 20.3372 14.5512 20.0731C13.9242 20.808 12.9973 21.25 12 21.25C11.0027 21.25 10.0758 20.808 9.44875 20.0731Z"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill: var(--color, --fallback-color);
        }
    }
</style>
