<!-- eslint-disable max-len -->
<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.325 3.25C14.6725 1.53832 16.1858 0.249999 18 0.249999C20.0711 0.25 21.75 1.92893 21.75 4C21.75 6.07107 20.0711 7.75 18 7.75C16.1858 7.75 14.6725 6.46168 14.325 4.75L8.00001 4.75C6.43147 4.75 5.12245 5.86118 4.81721 7.3393C6.4953 7.71226 7.75001 9.2096 7.75001 11C7.75001 12.7904 6.4953 14.2877 4.81721 14.6607C5.12246 16.1388 6.43146 17.25 8.00001 17.25L14.325 17.25C14.6725 15.5383 16.1858 14.25 18 14.25C20.0711 14.25 21.75 15.9289 21.75 18C21.75 20.0711 20.0711 21.75 18 21.75C16.1858 21.75 14.6725 20.4617 14.325 18.75L8.00001 18.75C5.60913 18.75 3.63082 16.9836 3.29897 14.6846C1.56296 14.3563 0.250005 12.8315 0.250006 11C0.250006 9.16852 1.56296 7.6437 3.29897 7.31541C3.63082 5.01643 5.60913 3.25 8.00001 3.25L14.325 3.25ZM15.75 18C15.75 19.2426 16.7574 20.25 18 20.25C19.2426 20.25 20.25 19.2426 20.25 18C20.25 16.7574 19.2426 15.75 18 15.75C16.7574 15.75 15.75 16.7574 15.75 18ZM6.25001 11C6.25001 12.2423 5.24321 13.2494 4.00105 13.25L4.00001 13.25C2.75737 13.25 1.75001 12.2426 1.75001 11C1.75001 9.75736 2.75737 8.75 4.00001 8.75C5.24265 8.75 6.25001 9.75736 6.25001 11ZM15.75 4C15.75 5.24264 16.7574 6.25 18 6.25C19.2426 6.25 20.25 5.24264 20.25 4C20.25 2.75736 19.2426 1.75 18 1.75C16.7574 1.75 15.75 2.75736 15.75 4Z" fill="#98A2B3"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg {
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path {
            fill: var(--color, --fallback-color);
        }
    }
</style>
