<!-- eslint-disable max-len -->
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0008 4.45565C10.5148 5.06626 9.96964 6.00397 9.18742 7.35508L5.12521 14.3716C4.34019 15.7276 3.79635 16.6709 3.50776 17.3989C3.22163 18.1207 3.27114 18.4251 3.38604 18.6244C3.50095 18.8237 3.73953 19.019 4.50754 19.133C5.28217 19.248 6.37104 19.25 7.93784 19.25H16.0623C17.6291 19.25 18.7179 19.248 19.4926 19.133C20.2606 19.019 20.4992 18.8237 20.6141 18.6244C20.729 18.4251 20.7785 18.1207 20.4924 17.3989C20.2038 16.6709 19.6599 15.7276 18.8749 14.3716L14.8127 7.35509C14.0305 6.00398 13.4853 5.06626 12.9993 4.45565C12.5177 3.8505 12.2299 3.74173 12.0001 3.74173C11.7702 3.74173 11.4824 3.8505 11.0008 4.45565ZM9.82709 3.52159C10.4033 2.79755 11.0768 2.24173 12.0001 2.24173C12.9233 2.24173 13.5968 2.79755 14.173 3.5216C14.7366 4.2298 15.3364 5.26581 16.0781 6.54703L16.1108 6.60353L20.173 13.6201L20.2058 13.6766L20.2059 13.6767L20.2059 13.6768C20.9504 14.9627 21.5522 16.0022 21.8868 16.8462C22.2287 17.7087 22.3758 18.5718 21.9136 19.3736C21.4513 20.1753 20.6306 20.4805 19.7128 20.6167C18.8148 20.7501 17.6136 20.75 16.1276 20.75H16.0623H7.93784H7.8725C6.38653 20.75 5.18526 20.7501 4.28727 20.6167C3.36953 20.4805 2.5488 20.1753 2.08656 19.3736C1.62431 18.5718 1.77141 17.7086 2.11333 16.8461C2.44788 16.0022 3.04978 14.9626 3.79432 13.6766L3.79433 13.6766L3.82707 13.6201L7.88928 6.60353L7.92199 6.54703C8.66372 5.26581 9.26349 4.2298 9.82709 3.52159ZM12 8.25C12.4143 8.25 12.75 8.58578 12.75 9V13C12.75 13.4142 12.4143 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V9C11.25 8.58578 11.5858 8.25 12 8.25ZM12.0001 17C12.5523 17 13.0001 16.5523 13.0001 16C13.0001 15.4477 12.5523 15 12.0001 15C11.4478 15 11.0001 15.4477 11.0001 16C11.0001 16.5523 11.4478 17 12.0001 17Z"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            fill: var(--color, --fallback-color);
        }
    }
</style>
