<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.25 11C21.25 5.33908 16.6609 0.75 11 0.75C5.33908 0.75 0.75 5.33908 0.75 11C0.75 16.6609 5.33908 21.25 11 21.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.3666 15.1C9.72438 15.1 8.2666 13.6422 8.2666 11C8.2666 8.3578 9.72438 6.90002 12.3666 6.90002" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.8999 9.6333H12.3666" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.8999 12.3667H11.4555" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 18.2095C15.5 16.7131 16.7131 15.5 18.2095 15.5C19.7059 15.5 20.9189 16.7131 20.9189 18.2095C20.9189 19.7059 19.7059 20.9189 18.2095 20.9189C16.7131 20.9189 15.5 19.7059 15.5 18.2095ZM18.2095 14C15.8846 14 14 15.8846 14 18.2095C14 20.5343 15.8846 22.4189 18.2095 22.4189C19.101 22.4189 19.9277 22.1418 20.6083 21.669L21.571 22.6317C21.8639 22.9246 22.3388 22.9246 22.6317 22.6317C22.9246 22.3388 22.9246 21.8639 22.6317 21.571L21.669 20.6083C22.1418 19.9277 22.4189 19.101 22.4189 18.2095C22.4189 15.8846 20.5343 14 18.2095 14Z" fill="currentColor"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path[stroke]{
            stroke: var(--color, var(--fallback-color));
        }
        path[fill]{
            fill: var(--color, var(--fallback-color));
        }

        /** @todo color in value instead of inline */
    }
</style>
