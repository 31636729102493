<!-- eslint-disable max-len -->
<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.197 2.53033C5.48989 2.23744 5.48989 1.76256 5.197 1.46967C4.9041 1.17678 4.42923 1.17678 4.13634 1.46967L1.46967 4.13634C1.17678 4.42923 1.17678 4.9041 1.46967 5.197L4.13634 7.86366C4.42923 8.15656 4.9041 8.15656 5.197 7.86366C5.48989 7.57077 5.48989 7.0959 5.197 6.803L3.81066 5.41667H11.3333C11.7475 5.41667 12.0833 5.08088 12.0833 4.66667C12.0833 4.25245 11.7475 3.91667 11.3333 3.91667H3.81066L5.197 2.53033ZM10.803 9.19699C10.5101 8.9041 10.5101 8.42922 10.803 8.13633C11.0959 7.84344 11.5708 7.84344 11.8637 8.13633L14.5303 10.803C14.8232 11.0959 14.8232 11.5708 14.5303 11.8637L11.8637 14.5303C11.5708 14.8232 11.0959 14.8232 10.803 14.5303C10.5101 14.2374 10.5101 13.7626 10.803 13.4697L12.1893 12.0833H4.66667C4.25245 12.0833 3.91667 11.7475 3.91667 11.3333C3.91667 10.9191 4.25245 10.5833 4.66667 10.5833H12.1893L10.803 9.19699Z" stroke-width="0px" fill="currentColor"/>
    </svg>
</template>

<style lang="scss" scoped>
    svg{
        --fallback-color: rgb(var(--color-gray-400));
        @apply m-auto;

        path{
            stroke: var(--color, --fallback-color);
        }
    }
</style>
